import {Badge, Button, Card, Row, Tooltip} from "antd";
import Icon from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import WaterModal from "../modals/water-modal";
import WeightModal from "../modals/weight-modal";
import {useDispatch, useSelector} from "react-redux";
import {getDailyActivites} from "../../actions";
import {dateDifference} from "../../helpers";
import moment from "moment";
import {GlassIcon, ScaleIcon, TrainingIcon} from "../../icons";
import TrainingModal from "../modals/training-modal";
import dayjs from "dayjs";
import MealPlanPrint from "./meal-plan-print";

const {Meta} = Card;

const DailyActivityCard = ({minimal, print, todayOnly}) => {
    const {t} = useTranslation();
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const date = useSelector(state => state.date);
    const menu = useSelector(state => state.menu.data);
    const physicalActivities = useSelector(state => state.physical_activities.data);
    const dailyActivites = useSelector(state => state.daily_activities.data);
    const [activeDate, setActiveDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [trainingBadge, setTrainingBadge] = useState(false);
    const user = useSelector(state => state.user.data);
    const recommendedDailyWater = user.weight * 32 - 1250;

    useEffect(() => {
        if (todayOnly) {
            dispatch(getDailyActivites(moment().format("YYYY-MM-DD")))
        }
    }, [date]);

    useEffect(() => {
        if (dateDifference(activeDate) === 0) {
            setTrainingBadge(physicalActivities.includes(0));
        }
    }, [physicalActivities, activeDate]);

    if (minimal) {
        return (
            <Row style={{gap: 8}} className="daily-action-buttons">
                <TrainingModal key="training-modal" visible={modal === 'AddTrainingModal'} setVisible={setModal} activeDate={activeDate} setActiveDate={setActiveDate}/>
                <WeightModal key="weight-modal" visible={modal === 'AddWeightModal'} setVisible={setModal}/>
                <WaterModal key="water-modal" visible={modal === 'AddWaterModal'} setVisible={setModal}/>

                <Tooltip key={"training"} title={t('goals.training.add-training')}>
                    <Badge
                        count={trainingBadge ? "✓" : 0}
                        style={{backgroundColor: '#52c41a'}}
                    >
                        <Button onClick={() => setModal('AddTrainingModal')} size="large"
                                icon={<Icon
                                    component={TrainingIcon}/>}>
                        </Button>
                    </Badge>
                </Tooltip>
                <Tooltip key={"weight"} title={t('goals.weight.add-weight')}>
                    <Badge
                        count={dailyActivites.weight ? "✓" : 0}
                        style={{backgroundColor: '#52c41a'}}
                    >
                        <Button onClick={() => setModal('AddWeightModal')} size="large"
                                icon={<Icon component={ScaleIcon}/>}>
                        </Button>
                    </Badge>
                </Tooltip>
                <Tooltip key={"water"} title={t('drink.water')}>
                    <Badge
                        count={dailyActivites.water >= recommendedDailyWater ? "✓" : 0}
                        style={{backgroundColor: '#52c41a'}}
                    >
                        <Button onClick={() => setModal('AddWaterModal')} size="large"
                                icon={<Icon component={GlassIcon}/>}>
                        </Button>
                    </Badge>
                </Tooltip>

                {print && (
                    <MealPlanPrint data={menu[0]}/>
                )}
            </Row>
        )
    }

    return (
        <Card
            bordered={false}
            className={"daily-activity-card"}
        >
            <TrainingModal key="training-modal" visible={modal === 'AddTrainingModal'} setVisible={setModal} activeDate={activeDate} setActiveDate={setActiveDate}/>
            <WeightModal key="weight-modal" visible={modal === 'AddWeightModal'} setVisible={setModal}/>
            <WaterModal key="water-modal" visible={modal === 'AddWaterModal'} setVisible={setModal}/>

            <Meta
                title={t('meal-plan.daily-activities')}
                description={
                    <Row style={{gap: 8}}>
                        <Tooltip key={"training"} title={t('goals.training.add-training')}>
                            <Badge
                                count={trainingBadge ? "✓" : 0}
                                style={{backgroundColor: '#52c41a'}}
                            >
                                <Button style={{fontSize: 20, padding: 0}} onClick={() => setModal('AddTrainingModal')} size="large"
                                        icon={<Icon
                                            component={TrainingIcon}/>}>
                                </Button>
                            </Badge>
                        </Tooltip>
                        <Tooltip key={"weight"} title={t('goals.weight.add-weight')}>
                            <Badge
                                count={dailyActivites.weight ? "✓" : 0}
                                style={{backgroundColor: '#52c41a'}}
                            >
                                <Button style={{fontSize: 20, padding: 0}} onClick={() => setModal('AddWeightModal')} size="large"
                                        icon={<Icon component={ScaleIcon}/>}>
                                </Button>
                            </Badge>
                        </Tooltip>
                        <Tooltip key={"water"} title={t('drink.water')}>
                            <Badge
                                count={dailyActivites.water >= recommendedDailyWater ? "✓" : 0}
                                style={{backgroundColor: '#52c41a'}}
                            >
                                <Button style={{fontSize: 20, padding: 0}} onClick={() => setModal('AddWaterModal')} size="large"
                                        icon={<Icon component={GlassIcon}/>}>
                                </Button>
                            </Badge>
                        </Tooltip>
                    </Row>
                }
            />
        </Card>
    )
}

export default DailyActivityCard;
