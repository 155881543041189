import React from 'react';
import ReactToPrint from 'react-to-print';
import {Button, Col, Row, Tooltip} from 'antd';
import Ingredient from "./ingredient.jsx";
import {getMealName, trackEvent} from "../../helpers";
import {withTranslation} from 'react-i18next';
import moment from "moment";
import {PrintIcon} from "../../icons";
import Icon from "@ant-design/icons";

class ComponentToPrint extends React.Component {
    render() {
        return (
            <Row className="meal-plan-print-view" gutter={20}>
                {
                    this.props.data && (
                        this.props.data.meals.map(meal => {
                            return (
                                <Col className="print-meal" key={meal.id} span={24}>
                                    <Row type="flex" gutter={20}>
                                        <Col span={24}>
                                            <p className="print-meal-title">{meal.recipe.original_name}</p>
                                        </Col>

                                        <Col span={12}>
                                            <p>{getMealName(meal.meal_type)} / {moment(this.props.data.date).format("Do MMMM")}</p>
                                            <Row className="print-meal-ingredients">
                                                {
                                                    meal.ingredients.map(ingredient => {
                                                            return <Col key={ingredient.id} span={24}
                                                                        className="print-meal-ingredient">
                                                                <Ingredient ingredient={ingredient} disabled={true}/>
                                                            </Col>
                                                        }
                                                    )
                                                }

                                                {meal.condiments.map(condiment => {
                                                    return <span style={{textTransform: "lowercase"}}
                                                                 key={condiment}>{condiment} </span>
                                                })}

                                            </Row>
                                        </Col>

                                        <Col span={12}>
                                            <p className="title">{this.props.title}</p>
                                            <div dangerouslySetInnerHTML={{__html: meal.recipe.instructions}}/>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })
                    )
                }
            </Row>
        );
    }
}

class MealPlanPrint extends React.Component {
    render() {
        const {t} = this.props;

        return (
            <>
                <ReactToPrint
                    onAfterPrint={() => (trackEvent('printed meal plan'))}
                    trigger={() =>
                        <Tooltip key={"print"} title={t('shopping-list.actions.print')}>
                                <Button style={{fontSize: 20, padding: 0}} size="large"
                                        icon={<Icon component={PrintIcon}/>}>
                                </Button>
                        </Tooltip>}
                    content={() => this.componentRef}
                />

                <ComponentToPrint title={t('recipes.info.instructions')} data={this.props.data}
                                  ref={el => (this.componentRef = el)}/>
            </>
        );
    }
}

export default withTranslation()(MealPlanPrint);