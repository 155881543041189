import {App, Button, Form, Input, InputNumber, Modal, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {convert, isImperialSystem, trackEvent, trackUser, updateItemById} from "../../helpers";
import {dailyActivitiesSuccess, fetchWeightLogSuccess, getGoal, getUserData,} from "../../actions";
import Api from "../../api";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getMaxWeight, getMinWeight} from "../../helpers/user";
import {goal_trophy, info_icon} from "../../assets";
import {Map} from "immutable";
import MotivationPopup from "./motivation-popup";
import Icon, {InfoCircleOutlined} from "@ant-design/icons";
import FocusLock from "react-focus-lock";

const {TextArea} = Input;

const WeightModal = ({visible, setVisible}) => {
    const {t} = useTranslation();
    const user = useSelector(state => state.user.data);
    const weights = useSelector(state => state.weights.data);
    const loading = useSelector(state => state.weights.loading);
    const daily_activities = useSelector(state => state.daily_activities.data);
    const [weightUpdated, setWeightUpdated] = useState(false);
    const dispatch = useDispatch();
    const [focus, setFocus] = useState(true);
    const { message } = App.useApp();

    useEffect(() => {
        // Disable focuslock
        setTimeout(() => {
            setFocus(!visible);
        }, 1000);
    }, [visible])


    const updateTodaysWeight = (values) => {
        if (!values.weight) {
            return false;
        }

        // Imperial weight convert to kg
        if (isImperialSystem(user.unit_system)) {
            values['weight'] = convert.poundsToKg(values['weight']);
        }

        Api.post('/v1/user/weight-logs', values).then((res) => {
            message.success(t('goals.weight.modal.update-success'));

            let userData = {
                current_weight: values.weight
            };

            if (!user.weight) {
                userData['initial_weight'] = values.weight
            }

            trackUser(userData);
            trackEvent('updated weight', {
                source: window.location.href,
                date: moment().format("YYYY-MM-DD"),
                weight: values.weight
            });

            // Show motivation popup
            // Dont show if goal is to maintain weight
            if (user.goal !== 2) {
                if (user.weight > values.weight) {
                    setWeightUpdated("lost");
                } else {
                    // Do not show weight gained for is_pregnant users
                    if (!user.is_pregnant) {
                        // Show only if weight has gone up more than 300 grams
                        if ((+user.weight + 0.3) < values.weight) {
                            setWeightUpdated("gained");
                        }
                    }
                }
            }

            if (!loading) {
                const weightLog = updateItemById(weights, res.data.data);

                dispatch(fetchWeightLogSuccess(weightLog));
                dispatch(getGoal());
            }

            const activities = Map(daily_activities);
            const updatedActivities = activities.setIn(['weight'], values.weight);

            dispatch(dailyActivitiesSuccess(updatedActivities.toJS()));

            // Reload user data
            dispatch(getUserData());
        }).catch(error => {
            message.error(error);
        });

        setVisible(false);
    };

    const minWeight = getMinWeight(user.unit_system);
    const maxWeight = getMaxWeight(user.unit_system);

    return (
        <>
            {weightUpdated && (
                <MotivationPopup showPopup={weightUpdated}
                                 title={weightUpdated === 'lost' ? t('motivation.weight-loss') : t('motivation.weight-gained')}
                                 description={weightUpdated === 'lost' ? t('motivation.weight-loss-info') : t('motivation.weight-gain-info')}
                                 type="weight"
                                 image={weightUpdated === 'lost' ? goal_trophy : info_icon}
                />
            )}

            {visible && (
                <Modal
                    title={
                        <>
                            {t('goals.weight.modal.title')} <Tooltip rootClassName="tooltip-in-modal" title={t('goals.weight.modal.tip.content')}>
                            <Icon component={InfoCircleOutlined}/>
                        </Tooltip>
                        </>
                    }
                    open={!!visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    footer={false}
                >
                    <br/>
                    <Form
                        className="text-center"
                        name="weight_form"
                        initialValues={{
                            remember: true,
                        }}
                        onError={(e) => console.log(e)}
                        onFinish={updateTodaysWeight}
                    >
                        <FocusLock disabled={!focus}>
                            <Form.Item
                                name="weight"
                                initialValue={isImperialSystem(user.unit_system) ? convert.kgToPounds(daily_activities.weight) : daily_activities.weight}
                                rules={[{
                                    type: "number",
                                    min: minWeight,
                                    max: maxWeight,
                                    message: t('goals.weight.modal.error', {min: minWeight, max: maxWeight})
                                }, {
                                    required: true, message: t('goals.weight.modal.value-required')
                                }]}
                                validateTrigger="onBlur"
                            >
                                <InputNumber step={"0.1"} decimalSeparator={","} inputmode="decimal" min={minWeight}
                                             max={maxWeight} size="large"
                                             addonAfter={isImperialSystem(user.unit_system) ? t('unit.lbs') : t('unit.kg')}/>
                            </Form.Item>
                        </FocusLock>

                        <Form.Item
                            name="comment"
                            initialValue={""}
                        >
                            <TextArea className="weight-comment-textarea" spellcheck="false" placeholder={t('weight.comment.placeholder')} rows={2}/>
                        </Form.Item>

                        <Button size="large" type="primary" htmlType="submit">
                            {t('common.save')}
                        </Button>
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default WeightModal;
