import {App, Button, Col, Input, Modal, Progress, Row, Space, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../helpers";
import {getDailyActivites, getWaterLog} from "../../actions";
import Api from "../../api";
import {useTranslation} from "react-i18next";
import moment from "moment";
import Icon, {InfoCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";

const {Text} = Typography;

const WaterModal = ({visible, setVisible, success}) => {
    const {t} = useTranslation();
    const [water, setWater] = useState(0);
    const [dailyWater, setDailyWater] = useState(0);
    const [edit, setEdit] = useState(false);
    const date = moment().format("YYYY-MM-DD");
    const dispatch = useDispatch();
    const waterLog = useSelector(state => state.water.data);
    const user = useSelector(state => state.user.data);
    const recommendedDailyWater = parseInt(user.weight * 32 - 1250);
    const { message } = App.useApp();

    // Load water log
    useEffect(() => {
        if (isEmpty(waterLog)) {
            dispatch(getWaterLog(date));
        }
    }, []);

    // Set input to zero
    useEffect(() => {
        if (!visible) {
            setWater(0);
            setEdit(false);
        }
    }, [visible])

    // Find todays water from log
    useEffect(() => {
        let todaysWater = 0;

        waterLog.map(value => {
            if (value.date === date) {
                todaysWater = value.amount;
            }
        });

        setDailyWater(todaysWater);
    }, [waterLog, date])


    const updateWater = (value) => {
        if (value < 0) {
            return setWater(0)
        }

        setWater(value);
    }

    const saveWater = () => {
        let amount = edit ? parseInt(water) : parseInt(dailyWater) + parseInt(water);

        if (water <= 0) {
            return false;
        }

        Api.post('/v1/user/water-logs', {amount: amount, date: date}).then(response => {
            setDailyWater(response.data.data.amount);
            setWater(0);
            message.success(t('daily-water.saved'));

            // Reload waterlog
            dispatch(getWaterLog(date));
            dispatch(getDailyActivites(date));
            setEdit(false);
        });
    }

    const waterProgress = () => {
        let percent = 0;

        percent = dailyWater / recommendedDailyWater * 100;


        return parseInt(percent);
    }

    return (
        <Modal
            title={

                <>
                    {t('daily-water.title', {date: date})} <Tooltip rootClassName="tooltip-in-modal" title={t('daily-water.tip.content')}>
                        <Icon component={InfoCircleOutlined}/>
                    </Tooltip>
                </>
          }
            open={!!visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={false}
        >
            <br/>
            <Space direction="vertical" size={30} style={{width: "100%"}}>
                <Row type="flex" justify="center" align="middle">
                    <Button disabled={water === 0} icon={<MinusOutlined/>} onClick={() => updateWater(+water - 200)}></Button>
                    <Input className="daily-water-input" type="number" addonAfter="ml" defaultValue={0} value={water} onChange={(e) => setWater(e.target.value)}/>
                    <Button icon={<PlusOutlined/>} onClick={() => updateWater(+water + 200)}></Button>
                </Row>

                <Row>

                {!edit && (
                    <Text className="text-center">{t('daily-water.consumed')} <strong>{dailyWater} / {recommendedDailyWater} ml</strong></Text>
                )}
                <Progress style={{marginTop: 0}} className={"water-progress"} showInfo={(waterProgress() >= 100)} percent={waterProgress()}/>
                </Row>


                <Row type="flex" justify="center" align="middle" gutter={20}>
                    {!edit && (
                        <Col span={12} md={8}>
                            <Button block onClick={() => {
                                setEdit(true);
                                setWater(dailyWater);
                            }} type="default" size="large">{t('common.edit')}</Button>
                        </Col>
                    )}
                    <Col span={12} md={8}>
                        <Button block onClick={saveWater} type="primary" size="large">{edit ? t('common.save') : t('common.add')}</Button>
                    </Col>
                </Row>
            </Space>
        </Modal>
    )
}

export default WaterModal;
