import {Col, Divider, Radio, Typography} from "antd";
import React from "react";
import {withTranslation} from "react-i18next";
import {mastercard, visa} from "../../assets.js";
import {productPrice} from "../../helpers/products";
import {useSelector} from "react-redux";

const {Title, Text} = Typography;

const PaymentMethods = ({paymentMethods, setPaymentMethod, active, t, product}) => {
    const user = useSelector(state => state.user.data);
    const totalPrice = productPrice(product); // Hide ESTO payment method if price is below 36€
    const whiteListedEmails = ['kukk.priit@gmail.com', 'renku3@gmail.com', 'irina.vihhoreva@gmail.com', 'indroo@live.com'];

    return (
        <Col span={24} id="payment-methods-section">
            <Divider orientation="center">
                <Title style={{margin: 0}} level={4}>{t('payments.choose-method')}</Title>
            </Divider>

            <Radio.Group value={active} className="payment-methods radio-buttons">
                {
                    paymentMethods.map((method, index) => {
                        if (method === 'esto' && totalPrice < 36) {
                            return false;
                        }

                        // Hide fortumo payment method
                        if (method === 'fortumo-hosted-dcb') {
                            return false;
                        }

                        return (
                            <Radio.Button key={index} onClick={() => setPaymentMethod(method)} value={method}>
                                {t('payment_methods.' + method)} {method === 'stripe' && (<><img src={mastercard} alt="mastercard"/> <img src={visa} alt="visa"/></>)}
                            </Radio.Button>
                        )
                    })
                }
            </Radio.Group>
        </Col>
    )
};

export default withTranslation()(PaymentMethods);
