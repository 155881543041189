import React, {useEffect, useRef} from 'react';
import {Row, Typography} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {dateDifference} from '../../helpers';
import {setMenuDate} from '../../actions';

const {Text} = Typography;
const allowed_prev_days = -7;
const allowed_next_days = 10;

const dateRange = [];
for (let day = allowed_prev_days; day <= allowed_next_days; day++) {
    dateRange.push(day);
}

const MenuDate = () => {
    const {t} = useTranslation();
    const selected_date = useSelector((state) => state.date);
    const dispatch = useDispatch();
    const itemRefs = useRef({});

    const changeDate = (days_diff) => {
        const selected_date = moment().add(days_diff, 'days').format('YYYY-MM-DD');
        dispatch(setMenuDate(selected_date));
    };

    useEffect(() => {
        setTimeout(() => {
            scrollCenter('smooth');
        }, 250);
    }, [selected_date]);

    const scrollCenter = (behavior) => {
        const element = itemRefs.current[dateDifference(selected_date)];
        if (element) {
            element.scrollIntoView({
                inline: 'center',
                behavior: behavior,
                block: 'end'
            });
        }
    };

    return (
        <Row className="menu-date-mobile" type="flex" align="middle" justify="space-around" gutter={12}>
            <ul>
                {dateRange.map((days_diff) => (
                    <li
                        className={"menu-date " + (dateDifference(selected_date) == days_diff ? 'active' : '')}
                        key={days_diff}
                        ref={(el) => (itemRefs.current[days_diff] = el)}
                        onClick={() => changeDate(days_diff)}
                    >
                        <Text>
                            {days_diff === 0 ? (
                                t('meal-plan.today')
                            ) : (
                                moment().add(days_diff, 'days').format('dd')
                            )}
                        </Text>
                        <Text strong>{moment().add(days_diff, 'days').format('DD')}</Text>
                    </li>
                ))}
            </ul>
        </Row>
    )
}

export default MenuDate;