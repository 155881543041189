import {filterObject, isDomain} from "../helpers.js";


/*
 * Product helpers
 */

export const productCurrency = (currency) => {
    let symbol = currency;

    switch (currency) {
        case 'EUR':
            symbol = '€';
            break;
        case 'USD':
            symbol = '$';
            break;
        case 'GBP':
            symbol = '£';
            break;
        case 'RUB':
            symbol = '₽';
        default:
            console.log('No symbol found!');
    }

    return symbol;
};

export const priceFormat = (price, product) => {
    let symbol = productCurrency(product.currency);

    if (product.currency == 'EUR' || product.currency == 'RUB') {
        return (price + symbol);
    } else {
        return (symbol + price);
    }
};

export const isFreeProduct = (product) => {
    return (product.price === 0)
};

export const productPrice = (product) => {
    return product.discount_price ? product.discount_price : product.price;
};

export const productMonthlyPrice = (product, period, type, format = true) => {
    let monthlyPrice = (Math.round((productPrice(product) / parseInt(period)) * 100) / 100).toFixed(2);

    if (type === 'recurring' && !isDomain('com')) {
        monthlyPrice = (Math.round((productPrice(product)) * 100) / 100).toFixed(2);
    }

    if(format === true) {
        return priceFormat(monthlyPrice, product);
    } else {
        return monthlyPrice;
    }
};

export const createProduct = (product, wpProducts) => {
    let wpProduct = filterObject(wpProducts, 'title', product.product_name)[0];

    // check if wordpress product exists with api product title
    if (!wpProduct) {
        return false;
    }

    // Set custom title from wordpress
    if (wpProduct.name) {
        product['custom_title'] = wpProduct.name;
    }

    if (wpProduct.popular) {
        product.popular = true;
    }

    // Set acf features
    if (wpProduct.features) {
        let features = [];
        for (const [key, value] of Object.entries(wpProduct)) {
            if (key.includes('_feature')) {
                features.push(value);
            }
        }

        product['features'] = features;
    }

    return product;
};
