import {ADVENT_CALENDER, APP_NAV_MENU, BANNERS, CUSTOM_UNITS, DARK_MODE, SHOW_CHAT} from '../actions/index';
import {getCookie} from "../helpers";

const initialState = {
    chat: true,
    banners: {},
    units: false,
    app_menu: getCookie('custom_app_nav') ? JSON.parse(getCookie('custom_app_nav')) : ['/meal-plan', '/diary', '/cookbook', '/goals', '/calculator', 'chat'],
    advent_calender: [],
    dark_mode: !!getCookie('darkmode'),
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_CHAT:
            return {
                ...state,
                chat: action.payload
            };
        case BANNERS:
            return {
                ...state,
                banners: action.payload
            };
        case CUSTOM_UNITS:
            return {
                ...state,
                units: action.payload.units
            };
        case APP_NAV_MENU:
            return {
                ...state,
                app_menu: action.payload.values
            };
        case ADVENT_CALENDER:
            return {
                ...state,
                advent_calender: action.payload
            };
        case DARK_MODE:
            return {
                ...state,
                dark_mode: action.payload
            };
        default:
            return state;
    }
}
