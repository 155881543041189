import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Modal, Row, Statistic, Typography} from "antd";
import {useSelector} from "react-redux";
import {config, dateDifference, isEmpty, redirect, trackEvent,} from "../../helpers";
import moment from "moment";
import {withTranslation} from 'react-i18next';
import PaymentSection from "../payment/payment-section";
import Icon from "@ant-design/icons";
import {SearchIcon} from "../../icons";

const {confirm} = Modal;
const {Title} = Typography;

const PremiumSubscription = ({t}) => {
    const access = useSelector(state => state.access.data);
    const recurring = useSelector(state => state.access.data.recurring ? state.access.data.recurring : {});

    let daysLeft = 0;

    if (!isEmpty(access)) {
        daysLeft = dateDifference(access.end_date) + 1;
    }

    if (daysLeft < 0) {
        daysLeft = 0;
    }

    useEffect(() => {
        // Reload always top page
        window.scrollTo(0, 0);

        trackEvent('opened payments');
    }, []);

    const isRecurringActive = () => {
        return (recurring.status === 'active');
    };

    return (
        <div className="meal-settings">
            <Row type="flex" justify="center">
                <Col span={24} md={24} xxl={14}>
                    <Divider orientation="left">
                        <Title level={4} style={{margin: 0}}>{t('premium.family.title')}</Title>
                    </Divider>

                    <Row>
                        <Alert style={{marginTop: 12}} message={t('premium.family.info')} type="info" showIcon/>
                    </Row>

                    <PaymentSection premium={true} col={8}/>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(PremiumSubscription);
