import React, {useState} from 'react';
import {Badge, Button, message, Select, Row} from 'antd'
import {dateDifference, isMobile, round} from "../../helpers";
import {isFreeProduct, productMonthlyPrice, productPrice} from "../../helpers/products.js";
import {withTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
const {Option} = Select;

const Product = ({data, active, setActive, t, type, premium, setMembers}) => {
    const access = useSelector(state => state.access.data);
    const user = useSelector(state => state.user.data);
    const recurring = useSelector(state => state.access.data.recurring ? state.access.data.recurring : {});
    let productData = data;
    let hasDiscount = !!productData.coupon_percentage;
    let isActive = active && active.id === productData.id ? 'active' : '';

    const title = productData.custom_title ? productData.custom_title : t('products.' + productData.product_name);

    const changeProduct = () => {
        if (productData.product_name === 'premium-' + user.allowed_family_members + 'members') {
            return message.error(t("Sul on juba see toode aktiivne!"));
        }

        if (type === 'recurring' && !isRecurringAvailable()) {
            return message.error(t("products.recurring.error"));
        }

        setActive(productData);
    };

    const isRecurringAvailable = () => {
        if (premium) {
            return true;
        }

        let daysLeft = dateDifference(access.end_date);

        return recurring.status !== 'active' && (daysLeft < 7);
    };

    // Product not found
    if (!productData) {
        return false;
    }

    const renderProductMembers = () => {
        if (premium) {
            return (
                <Row type="flex" justify="center" align="middle" style={{marginTop: 10}}>
                    <Select onClick={(e) => {e.stopPropagation()}} onChange={setMembers} style={{width: 140, textAlign: "left"}} defaultValue={1}>
                        <Option value={1}>{t('1.member')} {user.allowed_family_members === 1 ? "✔" : ""}</Option>
                        <Option value={2}>{t('2.member')} {user.allowed_family_members === 2 ? "✔" : ""}</Option>
                        <Option value={3}>{t('3.member')} {user.allowed_family_members === 3 ? "✔" : ""}</Option>
                    </Select>
                </Row>
            )
        }

        return null;
    }

    if (isMobile()) {
        return (
            <div
                onClick={() => changeProduct(productData)}
                className={`product-card mobile ${isActive}`}>
                <div className="card-body">
                    <p className="title">{title}</p>

                    <p className={`card-title pricing-card-title ${hasDiscount ? "text-primary" : ""}`}>
                        {isFreeProduct(productData) ? (t('products.free')) : (productMonthlyPrice(productData, productData.period, type, false) + ' ' + productData.currency)}

                        <span className="per-month text-muted">/{t('trial.month')}</span>

                        {productData.coupon_percentage && (
                            <small
                                className="text-muted price-before">{(round(type === 'single' ? (productData.price / parseInt(productData.period)) : productData.price, 2)) + ' ' + productData.currency}</small>
                        )}
                    </p>

                    {renderProductMembers()}

                    {productData.coupon_percentage && (
                        <div>
                            <Badge style={{backgroundColor: '#ea5b67'}} count={t('product.discount')  + "-" + round(productData.coupon_percentage, 0) + "%"}/>
                        </div>
                    )}

                    {productData.features && (
                        <ul className="list-unstyled mb-2 product-features">
                            {productData.features.map(feature => (
                                <li key={feature}><i className="bi bi-check2"></i> {feature}</li>
                            ))}
                        </ul>
                    )}

                    {type === 'single' && (
                        <p className="price-total">{t('products.total')}: <span
                            className="fw-600">{productPrice(productData) + ' ' + productData.currency}</span>
                        </p>
                    )}

                    <Button size="large" onClick={() => changeProduct(productData)} type="primary">{isActive ? t('products.selected') : t('products.select')}</Button>
                </div>
            </div>
        )
    } else {
        return (
            <div
                onClick={() => changeProduct(productData)}
                className={`product-card ${isActive}`}>
                <div className="card-body">
                    <p className="title">{title}</p>

                    <p className={`card-title pricing-card-title ${hasDiscount ? "text-primary" : ""}`}>
                        {isFreeProduct(productData) ? (t('products.free')) : (productMonthlyPrice(productData, productData.period, type, false) + ' ' + productData.currency)}

                        <span className="per-month text-muted">/{t('trial.month')}</span>

                        {productData.coupon_percentage && !productData.features && (
                            <small
                                className="text-muted price-before">{(round(type === 'single' ? (productData.price / parseInt(productData.period)) : productData.price, 2)) + ' ' + productData.currency}</small>
                        )}
                    </p>

                    {renderProductMembers()}

                    {productData.coupon_percentage && !productData.features && (
                        <div>
                            <Badge style={{backgroundColor: '#ea5b67'}} count={t('product.discount')  + "-" + round(productData.coupon_percentage, 0) + "%"}/>
                        </div>
                    )}

                    {productData.features && (
                        <ul className="list-unstyled mb-2 product-features">
                            {productData.features.map(feature => (
                                <li key={feature}><i className="bi bi-check2"></i> {feature}</li>
                            ))}
                        </ul>
                    )}

                    {type === 'single' && (
                        <p className="price-total">{t('products.total')}: <span
                        className="fw-600">{productPrice(productData) + ' ' + productData.currency}</span></p>
                    )}

                    <Button size="large" onClick={() => changeProduct(productData)} type="primary">{isActive ? t('products.selected') : t('products.select')}</Button>
                </div>
            </div>
        )
    }
};

export default withTranslation()(Product);
