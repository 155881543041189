import {App, Avatar, Button, Col, Form, List, Modal, Row, Tooltip, Typography} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dateDifference, getAmplitudeSource, trackEvent} from "../../helpers";
import {getDailyActivites, getPhysicalActivities, getUserMenu, physicalActivitiesSuccess,} from "../../actions";
import Api from "../../api";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useResizeEffect} from "../../resize-effect";
import Icon, {InfoCircleOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const {Text} = Typography;

const TrainingModal = ({visible, setVisible, activeDate, setActiveDate}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);
    const daily_activities = useSelector(state => state.daily_activities.data);
    const family_members = useSelector(state => state.family.family_members);
    const loading_family = useSelector(state => state.family.loading);
    const physical_activities = useSelector(state => state.physical_activities.data);
    const [family, setFamily] = useState([]);
    const {message} = App.useApp();
    const [form] = Form.useForm()
    const isMobile = useResizeEffect();

    useEffect(() => {
        // Add main user to family list
        if (family.length === 0 && !loading_family) {
            let familyData = [...family_members];

            familyData.unshift({
                id: 0,
                name: user.name_f,
                birthday: user.birthday,
                user_id: user.user_id
            })

            setFamily(familyData);
        }
    }, [family_members])

    useEffect(() => {
        // Get physical activities when date changes
        dispatch(getPhysicalActivities(moment(activeDate).format("YYYY-MM-DD")));
    }, [activeDate])

    useEffect(() => {
        // Update form training values if participants change
        form.setFieldsValue({training_participants: physical_activities});
    }, [form, physical_activities])


    const updateTraining = (id, added) => {
        let trainingParticipants = [...physical_activities];

        if (added) {
            trainingParticipants.push(id)
        } else {
            let indexToRemove = trainingParticipants.indexOf(id);

            if (indexToRemove !== -1) {
                trainingParticipants.splice(indexToRemove, 1);
            }
        }

        const data = {
            date: moment(activeDate).format("YYYY-MM-DD"),
            participants: trainingParticipants
        };

        Api.post('/v2/family-members/physical-activity', data).then((res) => {
            // Update physical activities
            let updated = [];

            res.data.data.map(participant => {
                if (participant.physical_activity) {
                    updated.push(participant.id)
                }
            });

            dispatch(physicalActivitiesSuccess(updated))

            // Update daily activites if today
            if (dateDifference(activeDate) === 0) {
                dispatch(getDailyActivites(activeDate));

                // Reload menu again after training updated
                dispatch(getUserMenu(activeDate, isMobile ? 1 : 4));
            }

            // Save event
            trackEvent('logged training', {
                source: getAmplitudeSource(),
                participants: trainingParticipants,
                date: activeDate
            });

            message.success(t('goals.training.updated'));
        });
    };

    return (
        <Modal
            title={
                <>
                    {t('goals.training.add-training')} <Tooltip rootClassName="tooltip-in-modal"
                                                                title={t('goals.training.tip.content')}>
                    <Icon component={InfoCircleOutlined}/>
                </Tooltip>
                </>
            }
            open={!!visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={false}
            className="training-modal"
        >
            <br/>
            <Row type="flex" justify="center">
                <DateSelector activeDate={activeDate} setActiveDate={setActiveDate}/>

                <Col span={24} md={20}>
                    <List
                        className="family-member-training-list"
                        itemLayout="horizontal"
                        dataSource={family}
                        renderItem={(member) => (
                            <List.Item
                                actions={physical_activities.includes(member.id) ? [<Button size="small" onClick={() => updateTraining(member.id, false)} type={"default"}>{t('remove.training')}</Button>] : [<Button size="small" onClick={() => updateTraining(member.id, true)} type={"primary"}>{t('save.training')}</Button>]}
                            >
                                <List.Item.Meta
                                    avatar={ <Avatar icon={<UserOutlined />} src={member.id === 0 ? user.avatar : ''} />}
                                    title={<Link to={member.id === 0 ? '/settings/personal' : ("/settings/family/member/" + member.id)}>{member.name}</Link>}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    )
}


    const allowed_prev_days = 0;
    const allowed_next_days = 10;

    const dateRange = [];
    for (let day = allowed_prev_days; day <= allowed_next_days; day++) {
        dateRange.push(day);
    }

    const DateSelector = ({activeDate, setActiveDate}) => {
        const {t} = useTranslation();
        const itemRefs = useRef({});

        const changeDate = (days_diff) => {
            const date = moment().add(days_diff, 'days').format('YYYY-MM-DD');

            setActiveDate(date)
        };

        useEffect(() => {
            setTimeout(() => {
                scrollCenter('smooth');
            }, 250);
        }, [activeDate]);

        const scrollCenter = (behavior) => {
            const element = itemRefs.current[dateDifference(activeDate)];
            if (element) {
                element.scrollIntoView({
                    inline: 'center',
                    behavior: behavior,
                    block: 'end'
                });
            }
        };

        return (
            <Row className="menu-date-mobile" type="flex" align="middle" justify="space-around" gutter={12}>
                <ul>
                    {dateRange.map((days_diff) => (
                        <li
                            className={"menu-date " + (dateDifference(activeDate) == days_diff ? 'active' : '')}
                            key={days_diff}
                            ref={(el) => (itemRefs.current[days_diff] = el)}
                            onClick={() => changeDate(days_diff)}
                        >
                            <Text>
                                {days_diff === 0 ? (
                                    t('meal-plan.today')
                                ) : (
                                    t('meal-plan.weekday', {date: moment().add(days_diff, 'days')})
                                )}
                            </Text>

                            <Text strong>{moment().add(days_diff, 'days').format('DD')}</Text>
                        </li>
                    ))}
                </ul>
            </Row>
        )
    }

    export default TrainingModal;
