import {useOutletContext} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    TimePicker,
    DatePicker,
    Divider, Input, List, message,
    Row,
    Select, Form,
    Typography, InputNumber, Statistic, Progress
} from 'antd';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {
    dateDifference, removeCookie, round, setCookie, trackEvent,
} from "../helpers";
import {setMenuDate, updateDarkMode} from "../actions";
import MenuDate from "../components/meal-plan/menu-date.jsx";
import {useResizeEffect} from "../resize-effect";
import DailyActivityCard from "../components/meal-plan/daily-activity-card";
import dayjs from "dayjs";
import {SearchIcon, TrainingStart} from "../icons";
import Icon, {
    PlayCircleOutlined,
    EditOutlined,
    LoadingOutlined,
    UpOutlined,
    PlayCircleFilled,
    StopOutlined, PauseCircleOutlined, PlusCircleFilled
} from "@ant-design/icons";
import SheetModal from "../components/blocks/sheet-modal";
import {
    CartesianGrid,
    ResponsiveContainer,
    XAxis,
    Cell,
    BarChart,
    Bar, ComposedChart, YAxis, Area, Tooltip, Tooltip as ChartTooltip, Legend
} from "recharts";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
const {Option} = Select;
const {Title, Text} = Typography;

const gymExercises = [
    {id: 1, name: "Jõutõmme", type: "weight"},
    {id: 2, name: "Rinnalt surumine", type: "weight"},
    {id: 10, name: "Biitseps hantliga", type: "weight"},
    {id: 3, name: "Kätekõverdused", type: "body_weight"},
    {id: 4, name: "Kükid", type: "body_weight"},
    {id: 9, name: "Kõhulihased", type: "body_weight"},
    {id: 5, name: "Jooksmine", type: "cardio"},
    {id: 6, name: "Ujumine", type: "cardio"},
    {id: 7, name: "Kõndimine", type: "cardio"},
    {id: 8, name: "Rattasõit", type: "cardio"},
];

const trainingCategories = [
    "weight",
    "body_weight",
    "cardio",
];

const TrainingDiary = () => {
    const {t} = useTranslation();
    const [outletData, setOutletData] = useOutletContext();
    const isMobile = useResizeEffect();
    const dispatch = useDispatch();
    const [showAll, setShowAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workout, setWorkout] = useState(false);
    const [trainingDiary, setTrainingDiary] = useState([]);
    const date = useSelector(state => state.date);
    const style = {
        background: "#0eb8ae59"
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [date]);

    const onChange = (date, dateString) => {
        if (!dateString) {
            return false;
        }

        dispatch(setMenuDate(dayjs(date).format('YYYY-MM-DD')));
    };

    const cellRender = (current, info) => {
        if (info.type !== 'date') {
            return info.originNode;
        }

        if (typeof current === 'number' || typeof current === 'string') {
            return <div className="ant-picker-cell-inner">{current}</div>;
        }

        return (
            <div className="ant-picker-cell-inner" style={current.date() === 15 ? style : {}}>
                {current.date()}
            </div>
        );
    };

    const selectWorkout = (workout) => {
        // TODO: Save workout to training diary api given date
        const data = {
            id: trainingDiary.length + 1,
            name: workout.name,
            type: workout.type,
            date: date,
            sets: [],
            distance: null,
            time: null,
        }

        setTrainingDiary([...trainingDiary, data])

        message.success('Trenn lisatud!');

        setShowAll(false);
    }

    const secondsToHMS = (seconds) => {
        const dur = dayjs.duration(seconds, 'seconds');
        const hours = String(dur.hours()).padStart(2, '0');
        const minutes = String(dur.minutes()).padStart(2, '0');
        const secs = String(dur.seconds()).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    }

    const calculateSetValue = (sets) => {
        let total = 0;

        if (sets.length > 0) {
            sets.map(set => {
                if (set) {
                    total += set.reps;
                }
            })
        }

        return total + ' kordust';
    }

    return (
        <div id="training-diary">
            <Row type="flex" justify="center">
                <Col span={24} md={24} lg={24} xxl={20}>
                    {isMobile ? (
                        <div>
                            <MenuDate/>
                        </div>
                    ) : (
                        <Row type="flex">
                            <Col span={24} md={16} lg={14}>
                                <Title level={1}>Trennipäevik</Title>

                                <Row type="flex" align="middle">
                                    <div>
                                        <DatePicker cellRender={cellRender} defaultValue={dayjs(date, 'YYYY-MM-DD')}
                                                    format={['DD.MM.YYYY', 'YY-MM-DD']} onChange={onChange}/>
                                    </div>
                                    <DailyActivityCard minimal={true}/>
                                </Row>
                            </Col>

                            <Divider orientation="left">
                                <Title level={4}
                                       style={{margin: 0}}>{dateDifference(date) === 0 ? t('meal-plan.today') + moment(date).format(", Do MMM") : moment(date).format("dddd, Do MMM")}</Title>
                            </Divider>
                        </Row>
                    )}


                    <Row type="flex" gutter={[40, 40]}>
                        <Col span={24} lg={14}>
                            {isMobile && (
                                <TrainingDailyProgress trainingDiary={trainingDiary}/>
                            )}

                            <div>
                                {trainingDiary.length > 0 ? (
                                    <div className="training-diary">
                                        <List
                                            className="hover-list"
                                            dataSource={trainingDiary}
                                            renderItem={training => {
                                                return (
                                                    <List.Item onClick={() => setWorkout(training)}>
                                                        <div>
                                                            <Text strong>{training.name}</Text>
                                                            <div className="list-sub-items">
                                                                {training.distance > 0 && (
                                                                    <span style={{
                                                                        display: "block",
                                                                        fontSize: 12,
                                                                        color: "grey"
                                                                    }}>{training.distance ? training.distance : 0}m</span>
                                                                )}
                                                                {training.time > 0 && (
                                                                    <span style={{
                                                                        display: "block",
                                                                        fontSize: 12,
                                                                        color: "grey"
                                                                    }}>{secondsToHMS(training.time)}</span>
                                                                )}
                                                                {(training.type !== 'cardio') && (
                                                                    <span style={{
                                                                        fontSize: 12,
                                                                        color: "grey"
                                                                    }}>{calculateSetValue(training.sets)}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="food-row-actions">
                                                            <Icon component={EditOutlined}/>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div style={{textAlign: "center", paddingTop: 40, paddingBottom: 40}}>
                                        <TrainingStart style={{
                                            width: 200,
                                            height: 200,
                                            filter: 'grayscale(1)',
                                            opacity: 0.3
                                        }}/>
                                    </div>
                                )}


                                <Row type="flex" justify="center">
                                    <Button icon={<PlusCircleFilled />} iconPosition={"end"} onClick={() => setShowAll(true)} type="primary" size="large">Add new exercise</Button>
                                </Row>
                            </div>
                        </Col>

                        <Col span={24} lg={10}>
                            {!isMobile && (
                                <TrainingDailyProgress trainingDiary={trainingDiary}/>
                            )}

                            <TrainingGraph/>
                        </Col>
                    </Row>

                    <SheetModal scroll={true} footer={false} open={showAll} close={() => setShowAll(false)}>
                        <div>
                            <Row type="flex" justify="center">
                                <Col span={20} md={14}>
                                    <Input
                                        onChange={onChange}
                                        placeholder={"Otsi trenn"}
                                        prefix={loading ? <LoadingOutlined spin/> :
                                            <Icon className="custom-icon" component={SearchIcon}/>}
                                        allowClear
                                        size="large"
                                    />
                                </Col>
                            </Row>

                                    <br/>
                            <Row type="flex" justify="center">
                                <Col span={24} md={20}>
                                    {false && (
                                        <div className="ingredient-filters scroll-wrapper">
                                            {trainingCategories.map(category => {
                                                return (<Button size="small">{category}</Button>)
                                            })}
                                        </div>
                                    )}

                                    <div id="infinite-ingredients">
                                        <List
                                            className="hover-list"
                                            dataSource={gymExercises}
                                            renderItem={workout => {
                                                return (<List.Item className="ingredient-list-item"
                                                                   onClick={() => selectWorkout(workout)}>
                                                    <div>
                                                        <Text>{workout.name}</Text>
                                                    </div>
                                                </List.Item>)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </SheetModal>

                    <EditWorkout trainingDiary={trainingDiary} setTrainingDiary={setTrainingDiary} setWorkout={setWorkout} workout={workout}/>
                </Col>
            </Row>
        </div>
    );
}

const TrainingDailyProgress = ({trainingDiary}) => {
    return (
        <div className="training-daily-progress shadow-box">
            <Row type="flex" align="middle" justify="center">
                <Col span={16}>
                    <Title style={{margin: 0}} level={5}>Your daily training volume</Title>
                    <Text className="muted-text">You have completed {trainingDiary.length * 10}% of total training</Text>
                </Col>
                <Progress type="circle" percent={trainingDiary.length * 10} size="small" strokeColor={{
                    '0%': '#ee7752',
                    '50%': '#e73c7e',
                }} />
            </Row>
        </div>
    )
}

const TrainingGraph = () => {
    const {t} = useTranslation();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (chartData.length === 0) {
            generateLog('week');
        }
    }, [chartData])

    const generateRandomVolume = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const generateLog = (type) => {
        const trainingLog = [];
        const currentYear = 2024;
        const currentMonth = 5; // May
        const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

        for (let day = 1; day <= daysInMonth; day++) {
            const date = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            const volume = generateRandomVolume(1, 10);
            trainingLog.push({date, volume});
        }

        if (type === 'week') {
            const datesForCurrentWeek = getDatesForCurrentWeek();
            const trainingLog = datesForCurrentWeek.map(date => ({
                date,
                volume: generateRandomVolume(1, 10)
            }));
            setChartData(trainingLog);
        } else {
            setChartData(trainingLog);

        }
    }


    function getDatesForCurrentWeek() {
        const currentDate = new Date();
        const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)); // Set to Monday
        const dates = [];

        for (let i = 0; i < 7; i++) {
            const date = new Date(startOfWeek);
            date.setDate(startOfWeek.getDate() + i);
            const formattedDate = date.toISOString().split('T')[0];
            dates.push(formattedDate);
        }

        return dates;
    }

    return (
        <div className="shadow-box">
            <Title level={4}>Activity <Select bordered={false} onSelect={(value) => generateLog(value)} defaultValue={"week"} style={{float: "right"}}>
                <Option value="week">This week</Option>
                <Option value="month">Month</Option>
            </Select></Title>

            <ResponsiveContainer className="goal-graph-container" width='100%' height={260}>
                <BarChart
                    style={{fontSize: 12, width: "100%"}}
                    data={chartData}
                    margin={{top: 5, bottom: 5}}
                >
                    <CartesianGrid strokeDasharray="3 5"/>

                    <XAxis dataKey="date" tickFormatter={(tickItem) => moment(tickItem).format('dd')}/>

                    <Tooltip cursor={false} />

                    <Bar onClick={e => console.log(e)} dataKey="volume" barSize={8} radius={[6, 6, 0, 0]}>
                        {chartData.map((entry, index) => {
                            const getBarColor = () => {
                                if (entry.volume > 6) {
                                    return "rgb(82, 196, 26)";
                                }

                                if (entry.volume > 3) {
                                    return "rgba(82,196,26,0.6)";
                                }

                                return "rgba(82,196,26,0.3)";
                            }

                            return (
                                <Cell cursor="pointer" fill={getBarColor()} key={`cell-${index}`} />
                            )
                        })}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>

    )
}

const ExerciseGraph = ({workout}) => {
    const {t} = useTranslation();
    const [chartData, setChartData] = useState([]);

    // Todo: get chart data by exercise id
    useEffect(() => {
        setChartData([
            {date: "2024-01-20", value: 30},
            {date: "2024-02-20", value: 34},
            {date: "2024-03-20", value: 39},
            {date: "2024-04-20", value: 42},
            {date: "2024-05-20", value: 45},
            {date: "2024-05-24", value: 52},
            {date: "2024-05-26", value: 55},
            {date: "2024-05-28", value: 57},
            {date: "2024-06-30", value: 62},
            {date: "2025-01-20", value: 62},
            {date: "2025-02-20", value: 64},
            {date: "2025-03-20", value: 67},
            {date: "2025-04-20", value: 68},
            {date: "2025-05-20", value: 75},
            {date: "2025-05-24", value: 72},
            {date: "2025-05-26", value: 71},
            {date: "2025-05-28", value: 70},
            {date: "2025-06-30", value: 80},
        ])
    }, [workout])

    const formatYAxis = (tickItem) => {
        if (workout.type === 'weight') {
            return tickItem + ' kg';
        }

        if (workout.type === 'cardio') {
            return tickItem + ' m';
        }

        if (workout.type === 'body_weight') {
            return tickItem + ' x';
        }
    }

    const getChartName = () => {
        if (workout.type === 'weight') {
            return 'Raskus';
        }

        if (workout.type === 'cardio') {
            return 'Vahemaa';
        }

        if (workout.type === 'body_weight') {
            return 'Kordused';
        }
    }

    const getExerciseColor = () => {
        if (workout.type === 'weight') {
            return '#7E8FEF';
        }

        if (workout.type === 'cardio') {
            return '#e73c7e';
        }

        if (workout.type === 'body_weight') {
            return '#efc207';
        }
    }

    return (
        <ResponsiveContainer className="training-chart-container" width='100%'
                             height={160}>
            <ComposedChart
                height={160}
                data={chartData}
                syncId="anyId"
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={getExerciseColor()} stopOpacity={0.3}/>
                        <stop offset="95%" stopColor={getExerciseColor()} stopOpacity={0}/>
                    </linearGradient>
                </defs>


                <XAxis tickFormatter={(tickItem) => moment(tickItem).format('Do MMM')} dataKey="date"/>

                <YAxis tickFormatter={formatYAxis} width={40} type="number"/>

                <ChartTooltip wrapperStyle={{ outline: "none" }} labelFormatter={(value) => (moment(new Date(value)).format('DD.MM.YYYY'))}/>

                <Area type="monotone" name={getChartName()} dataKey="value" stroke={getExerciseColor()} fill="url(#colorUv)" />
            </ComposedChart>
        </ResponsiveContainer>
    )
}


const useCountUp = () => {
    const [count, setCount] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        if (isRunning) {
            const id = setInterval(() => {
                setCount((prevCount) => prevCount + 1);
            }, 1000);
            setIntervalId(id);
        }

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, [isRunning]);

    const start = () => {
        setIsRunning(true);
    };

    const stop = () => {
        setIsRunning(false);
        clearInterval(intervalId);
    };

    return { count, start, stop, isRunning, setCount };
};

const EditWorkout = ({workout, setWorkout, trainingDiary, setTrainingDiary}) => {
    const [form] = Form.useForm();
    const { count, start, stop, isRunning, setCount } = useCountUp();

    // Format count to HH:mm:ss
    const formattedCount = dayjs().startOf('day').second(count).format('HH:mm:ss');

    useEffect(() => {
        if (workout) {
            form.setFieldValue('sets', workout.sets);
        }
    }, [workout])

    useEffect(() => {
        form.setFieldValue('time', dayjs(formattedCount, 'HH:mm:ss'))
    }, [count])

    const saveForm = () => {
        stop();

        const values = form.getFieldsValue();
        const time = form.getFieldValue('time');
        const distance = form.getFieldValue('distance');

        const data = [...trainingDiary];

        const trainingIndex = trainingDiary.findIndex((i) => {
            return i.id === workout.id;
        });

        if (workout.type !== 'cardio') {
            data[trainingIndex]['sets'] = values.sets;
        } else {
            data[trainingIndex]['time'] = count ? count : time;
            data[trainingIndex]['distance'] = distance;
        }

        setTrainingDiary(data)

        setWorkout(false);
    };

    const changeTimer = (e, timeString) => {
        function timeStringToSeconds(timeString) {
            const [hours, minutes, seconds] = timeString.split(':').map(Number);
            return (hours * 3600) + (minutes * 60) + seconds;
        }

        const totalSeconds = timeStringToSeconds(timeString);

        setCount(totalSeconds ? totalSeconds : 0);
    }


    return (
        <SheetModal scroll={true} footer={false} open={workout} close={saveForm}>
            <div>
                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>{workout.name}</Title>
                </Divider>

                <ExerciseGraph workout={workout}/>

                <Form
                    key={workout}
                    form={form}
                    name="dynamic_form_nest_item"
                    onFinish={saveForm}
                    style={{
                        marginTop: 20,
                        maxWidth: 'none',
                    }}
                    initialValues={{
                        sets: workout.sets,
                        distance: workout.distance,
                        time: workout.time,
                    }}
                    autoComplete="off"
                >
                    {workout.type === 'weight' || workout.type === 'body_weight' ? (
                        <Form.List name="sets" className="text-center">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <Space
                                            key={key}
                                            style={{display: 'flex', justifyContent: "center", marginBottom: 0,}}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'reps']}
                                            >
                                                <InputNumber style={{minWidth: 150}} decimalSeparator={","}
                                                             inputMode="decimal" placeholder={"Korduste arv"} min={0}
                                                             max={99}/>
                                            </Form.Item>
                                            <span className="muted-text">x</span>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'weight']}
                                            >
                                                <InputNumber className="weight-input-wrapper" style={{maxWidth: 150}}
                                                             disabled={workout.type === 'body_weight'} step={"0.1"}
                                                             decimalSeparator={","}
                                                             inputMode="decimal" placeholder={"0"} min={0}
                                                             max={999} addonAfter={'kg'}
                                                             controls
                                                />
                                            </Form.Item>

                                            <MinusCircleOutlined onClick={() => remove(name)}/>
                                        </Space>
                                    ))}

                                    <Form.Item className="text-center">
                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}>
                                            Add row
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    ) : (
                        <>
                            <Space
                                style={{display: 'flex', justifyContent: "center", marginBottom: 0,}}
                                align="baseline"
                            >
                                <Form.Item label={"Vahemaa"} name={'distance'}>
                                    <InputNumber style={{maxWidth: 150}}
                                                 inputmode="decimal"
                                                 addonAfter={"m"}
                                                 placeholder={"0"} min={0}
                                                 max={99999}/>
                                </Form.Item>

                                <Form.Item label={"Aeg"} name={'time'}>
                                    <TimePicker onChange={changeTimer}/>
                                </Form.Item>
                            </Space>


                            <Form.Item className="text-center">
                                <Space direction="horizontal">
                                    <Button type="primary" icon={<PlayCircleOutlined />} iconPosition={"end"}  onClick={start} disabled={isRunning}>Start</Button>
                                    <Button onClick={stop}  icon={<PauseCircleOutlined />} iconPosition={"end"} disabled={!isRunning}>Stop</Button>
                                </Space>
                            </Form.Item>

                        </>
                    )}
                </Form>
            </div>
        </SheetModal>
    );
}

export default TrainingDiary;