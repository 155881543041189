import React, {useEffect, useRef, useState} from "react";
import {Card, Col, message, Modal, Row, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import ReactCanvasConfetti from 'react-canvas-confetti';
import {withTranslation} from "react-i18next";
import Api from "../api";
import moment from "moment";
import Lottie from "react-lottie";
import {getAdventCalender, updateAdventCalender} from "../actions";
import {dateDifference, setCookie, trackEvent} from "../helpers";
import Icon, {SunOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

const AdventCalender = ({t}) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [reward, setReward] = useState('');
    const currentAdventDay = moment().format('DD');
    const confettiRef = useRef(null);
    const advent_calender = useSelector(state => state.app.advent_calender);

    useEffect(() => {
        dispatch(getAdventCalender());
    }, [])

    const close = () => {
        setVisible(false);
    }

    const startConfetti = () => {
        if (confettiRef && confettiRef.current) {
            confettiRef.current.confetti();
        }
    }

    const openPresent = (data) => {
        setCookie('calender-seen', true, 1);

        if (data.opened) {
            setReward(data.gift);
            setVisible('opened');
            setTimeout(() => {
                startConfetti();
            }, 500);
            return false;
        }

        Api.post('/v2/user/advent-calendar/' + data.id).then(res => {
            const calenderCopy = [...advent_calender];
            const cardIndex = advent_calender.findIndex(function (e) {
                return e.id === data.id;
            });

            calenderCopy[cardIndex]['opened'] = true;

            dispatch(updateAdventCalender(calenderCopy))

            setReward(data.gift);
            setVisible(true);

            trackEvent('opened gift', {
                date: data.date
            });

            setTimeout(() => {
                startConfetti();
            }, 500)
        }).catch(err => {
            if (err && err.response && err.response.data) {
                message.warning(t(err.response.data.message));
            } else {
                console.log(err);
                message.error('Api request failed');
            }
        })
    }

    const PresentModal = () => {
        return (
            <Modal
                className="main-modal level-up-modal christmas"
                title={false}
                visible={visible}
                onOk={close}
                onCancel={close}
                footer={false}
            >
                <ReactCanvasConfetti
                    style={{
                        width: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: 300
                    }}
                    ref={confettiRef}
                />
                <Row type="flex" justify="center" align="middle" className="text-center">
                    <h2>{visible === 'opened' ? t('advent-calender.opened') : t('advent-calender.reward')}</h2>
                </Row>

                <Row type="flex" justify="center" align="middle"  className="text-center">
                    <p className="fade-in-content" dangerouslySetInnerHTML={{__html: reward}}></p>
                </Row>
            </Modal>
        )
    }

    return (
        <div className="advent-calender-page">
                <div className={"bg-container"}>
                    {false && (
                        <div>

                            {[...Array(50)].map((e, i) => <div key={i} className="snowflake"></div>)}
                        </div>
                    )}
                    <div className="wavy-bg">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                             preserveAspectRatio="none">
                            <path
                                d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                                className="shape-fill"></path>
                        </svg>
                    </div>
                </div>

            <Row type="flex" justify="center" className="page-container">
                <PresentModal/>

                <Col span={24} md={20}>
                    <Row type="flex" align="middle" className="advent-calender-header">
                        <Col span={24}>
                            <Title level={1}><Icon component={SunOutlined}/> {t('advent-calender.title')}</Title>
                            <Title level={4}>{t('advent-calender.description')}</Title>
                        </Col>
                    </Row>
                </Col>

                <Col span={24} md={20}>
                    <Row type="flex" align="middle" className="advent-calender" gutter={[10, 10]}>
                        {advent_calender.map((e) => {
                            return (
                                <AdventCard key={e.id} data={e} openPresent={openPresent}/>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

const AdventCard = ({openPresent, data}) => {
    const isToday = dateDifference(data.date) === 0;
    const isStopped = !isToday || data.opened;
    const defaultOptions = {
        loop: true,
        autoplay: !isStopped,
        animationData: require("../../assets/videos/happy-giftbox.json"),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <Col span={6} md={6} xxl={3}>
            <Card onClick={() => openPresent(data)} bordered={false} className={"advent-card " + (data.opened ? "open" : "")}>
                <h1>{moment(data.date).format('DD')}</h1>

                <div className={"animated-gift " + (!isToday && !data.opened ? "disabled" : "")}>
                    <Lottie isClickToPauseDisabled={true} isStopped={isStopped} options={defaultOptions}/>
                </div>
            </Card>
        </Col>
    )
}

export default withTranslation()(AdventCalender);
