import React, {useEffect, useState} from "react";
import {Button, Modal, Row} from "antd";
import {useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import {getCookie, isApp, isEmpty, ReactNativeMessage, setCookie, trackEvent} from "../../helpers";
import moment from "moment";
import {ShareAltOutlined} from "@ant-design/icons";

const MotivationPopup = ({t, showPopup, title, description, image, type}) => {
    const [visible, setVisible] = useState(false);
    const menu = useSelector(state => state.menu.data);
    const [persistence, setPersistence] = useState(0);

    useEffect(() => {
        if (!isEmpty(menu)) {
            // Only update todays menu persistence
            if (moment(menu[0].date).isSame(moment(), 'day')) {
                const eatenMeals = menu[0].meals.filter(meal => {
                    return meal["has_eaten"] || meal["is_cheat_meal"];
                });

                setPersistence(eatenMeals.length * 25);
            }
        }
    }, [menu]);

    useEffect(() => {
        if (persistence === 100 || !!showPopup) {
            if (!getCookie('motivation_popup_' + type)) {
                setVisible(true);
                setCookie('motivation_popup_' + type, true, 7);
            }
        }
    }, [persistence, showPopup]);

    const close = () => {
        setVisible(false);
    }

    const share = () => {
        trackEvent('motivation share');

        if (isApp()) {
            ReactNativeMessage('FACEBOOK_SHARE ' + 'https://bit.ly/fitlap-levelid');
        } else {
            window.FB.ui({
                method: 'share',
                href: 'https://bit.ly/fitlap-levelid',
                description: t('levelup.share.message')
            });
        }
    }

    return (
        <Modal
            className={"level-up-modal motivation-popup " + type}
            title={false}
            open={visible}
            onOk={close}
            onCancel={close}
            footer={false}
        >
            <Row type="flex" justify="center" align={"middle"}>
                <img width="100" src={image} alt=""/>

                <h2 className="fade-in-content" style={{animationDelay: "0.5s"}}>{title}</h2>

                <p className="fade-in-content" style={{animationDelay: "1s"}}>{description}</p>
            </Row>

            <Row className="text-center" type="flex" justify="center" style={{gap: "20px"}}>
                <Button size="large" icon={<ShareAltOutlined/>} onClick={share}
                        ghost>{t('cookbook.share.button')}</Button>
                <Button size="large" type="default"
                        onClick={close}>{t('common.continue')}</Button>
            </Row>
        </Modal>
    )
}

export default withTranslation()(MotivationPopup);