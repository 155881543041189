import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Radio, Row, Skeleton, Statistic, Typography} from "antd";
import {useSelector} from "react-redux";
import {
    calculateTimeLeft,
    config,
    dateDifference,
    isDomain,
    isEmpty,
    paymentUrl,
    redirect,
    trackEvent
} from "../../helpers";
import moment from "moment";
import Product from "./product.jsx";
import {withTranslation} from 'react-i18next';
import PaymentMethods from "./payment-methods.jsx";
import Api from "../../api.js";
import {useResizeEffect} from "../../resize-effect";
import {createProduct} from "../../helpers/products";
import {Stebby} from "../../icons";

const { Countdown } = Statistic;
const {Title} = Typography;

const PaymentSection = ({t, premium, premiumAccounts}) => {
    const [products, setProducts] = useState(false);
    const [activeProduct, setActiveProduct] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [members, setMembers] = useState(1);
    const [page, setPage] = useState('');
    const [tab, setTab] = useState(isDomain('com') || premium ? 'recurring' : 'single');
    const app = useSelector(state => state.app);
    const recurring = useSelector(state => state.access.data.recurring ? state.access.data.recurring : {});
    const campaign = useSelector(state => state.app.banners);
    const access = useSelector(state => state.access.data);
    const user = useSelector(state => state.user.data);
    const discountEndTime = moment(user.added).add(3, 'days').format("YYYY-MM-DD HH:mm:ss");
    const timeLeft = calculateTimeLeft(discountEndTime);
    const isMobile = useResizeEffect();
    let daysLeft = 0;

    if (!isEmpty(access)) {
        daysLeft = dateDifference(access.end_date) + 1;
    }

    if (daysLeft < 0) {
        daysLeft = 0;
    }

    useEffect(() => {
        // Check if there is currently active campaign
        if (campaign.offer_url && !premium) {
            Api.get('/v2/blog/page?slug=' + campaign.offer_url).then(response => {
                let pageData = response.data.data;

                setPage(response.data.data);

                // Load products based on wordpress products
                setTimeout(() => {
                    loadProducts(pageData.fields.products);
                }, 1000)
            }).catch(err => {
                // Failed to load campaign page load default products
                let defaultCoupon = getDefaultCoupon();

                const defaultProducts = [
                    {title: "premium-single-12m", coupon: defaultCoupon},
                    {title: "premium-single-3m", coupon: defaultCoupon},
                    {title: "premium-single-1m", coupon: defaultCoupon},
                    {title: "premium-recurring-1m", coupon: defaultCoupon},
                    {title: "premium-recurring-12m", coupon: defaultCoupon},
                ]

                if (!premium) {
                    loadProducts(defaultProducts);
                }
            });
        } else {
            let defaultCoupon = getDefaultCoupon();

            let defaultProducts = [
                {title: "premium-single-12m", coupon: defaultCoupon},
                {title: "premium-single-3m", coupon: defaultCoupon},
                {title: "premium-single-1m", coupon: defaultCoupon},
                {title: "premium-recurring-1m", coupon: defaultCoupon},
                {title: "premium-recurring-12m", coupon: defaultCoupon},
            ]

            if (!premium) {
                loadProducts(defaultProducts);
            }
        }
    }, []);

    useEffect(() => {
        if (premium) {
            const defaultProducts = [
                {title: "premium-" + members + "members"},
            ]

            setActiveProduct('');
            loadProducts(defaultProducts);
        }
    }, [members]);

    const loadProducts = (productData) => {
        if (productData && productData.length > 0) {
            // Convert the array into a query string
            const queryParameters = productData.map((product, i) => {
                return 'title[' + i + ']=' + encodeURIComponent(product.title) + '&coupon[' + i + ']=' + encodeURIComponent(product.coupon ? product.coupon : getDefaultCoupon());
            }).join('&');

            Api.get('/v2/billing-plans/v2?' + queryParameters).then(response => {
                let products = response.data.data;

                products.map(product => {
                    createProduct(product, productData);

                    if (premium) {
                        product['features'] = ['50 000 toiduainet', '200 salvestatud retsepti', 'reklaamivabadus', 'tumerežiim', 'pere sünkroniseerimine (vt alt) jne'];
                    }
                })

                setProducts(products);
            });
        }
    };

    const getDefaultCoupon = () => {
        let defaultCoupon = null;

        // Premium products dont have discount
        if (premium) {
            return defaultCoupon;
        }

        // Apply new user discount or expired user discount
        if (app.banners && app.banners.show_new_user_discount) {
            // Set 3 day trial discount code per 24h
            if (!isEmpty(timeLeft)) {
                defaultCoupon = 'FITLAP30';

                if (timeLeft.days === 1) {
                    defaultCoupon = 'FITLAP20';
                }

                if (timeLeft.days === 0) {
                    defaultCoupon = 'FITLAP10';
                }
            }
        }

        if (daysLeft <= 7 && !defaultCoupon) {
            defaultCoupon = 'FITLAP30';
        }

        return defaultCoupon;
    }

    const setProduct = (value) => {
        setActiveProduct(value);
        setPaymentMethod('');
    };

    const changeTab = (value) => {
        setTab(value);
        setActiveProduct('');
    }

    const isRecurringAvailable = () => {
        if (premium) {
            return true;
        }

        let daysLeft = dateDifference(access.end_date);

        return recurring.status !== 'active' && (daysLeft < 5);
    };

    // After payment method is selected redirect to payment
    useEffect(() => {
        if (paymentMethod) {
            // Amplitude send event
            trackEvent('started amember payment', {
                source: window.location.pathname,
                product_id: activeProduct.id,
                payment_method: paymentMethod
            })

            if (config("analytics_id")) {
                window.gtag("event", "add_to_cart", {
                    source: window.location.pathname,
                    product_name: activeProduct.id,
                    product_id: activeProduct.id,
                    value: activeProduct.price,
                    tax: activeProduct.price * 0.2,
                    discount_price: activeProduct.discount_price,
                    currency: "EUR",
                    items: [
                        {
                            id: activeProduct.id,
                            name: activeProduct.product_name,
                            quantity: 1,
                            price: activeProduct.price
                        }
                    ]
                });
            }

            redirect(paymentUrl({
                payment_method: paymentMethod,
                product_id: activeProduct.id,
                coupon: activeProduct.coupon
            }));
        }
    }, [paymentMethod]);

    // Active 12 month installment payment dont show products
    if (access.installment_payment) {
        return false;
    }

    // Show no payment options for family member accounts
    if (user.is_family_member_account) {
        return false;
    }

    if (recurring.status === 'active') {
        return (
            <Alert className="text-center" message={t("products.recurring.active")} type="info" showIcon/>
        )
    }

    return (
        <Row className="payment-section" type="flex" justify="center">
            <Col span={24}>
                {isMobile ? (
                    <Title className="text-center" level={4}>{t('payment.buy-subscription')}</Title>
                ) : (
                    <Divider orientation="center">
                        <Title style={{margin: 0}} level={4}>{premium  ? t('payment.fitlap-plus') : t('payment.buy-subscription')}</Title>
                    </Divider>
                )}

                {!isDomain('com') && !premium &&  (
                    <Row type="flex" justify="center" style={{marginBottom: 20, marginTop: 20, gap: 8}}>
                        <Button size="large" type={tab === 'single' ? "primary" : "default"}
                                onClick={() => changeTab('single')}>
                            {t('products.single-payment')}
                        </Button>
                        <Button size="large" type={tab === 'recurring' ? "primary" : "default"}
                                onClick={() => changeTab('recurring')}>
                            {t('products.recurring-payment')}
                        </Button>
                        {config('feature.stebby') && (
                            <a onClick={() => trackEvent('stebby click')} target="_blank" href="https://fitlap.ee/s/StebbyFitlap">
                                <Button className="stebby-btn" size="large" type={"default"}>
                                    <img src={Stebby} alt="" style={{width: 70}}/>
                                </Button>
                            </a>
                        )}
                    </Row>
                )}

                {page && page.fields.offer_end_date && (
                    <Row type="flex" justify="center">
                        <Countdown className="offer-countdown" format={t('offer.countdown')} value={moment(page.fields.offer_end_date).format("YYYY-MM-DDTHH:mm:ss")}/>
                    </Row>
                )}

                {!isRecurringAvailable() && (tab === 'recurring') ? (
                    <Alert message={t("recurring.not-allowed")} type="info" showIcon/>
                ) : (
                    <Radio.Group style={{width: '100%'}} value={activeProduct.period} className="radio-buttons" size="small">
                        <Row className={"products"} type="flex" justify="center" gutter={20}>
                            {products ? products.map((data, index) => {
                                if (data.type === tab) {
                                    return (
                                        <Col key={index} span={24} md={12} xl={8} xxl={7}>
                                            <Product setMembers={setMembers} premium={premium} type={data.type} active={activeProduct} setActive={setProduct}
                                                     data={data}/>
                                        </Col>
                                    )
                                }
                            }) : (
                                ([...Array(3).keys()].map(index => (

                                    <Col span={24} md={8} xl={6}>
                                        <Skeleton className="post-skeleton" title paragraph={{rows: 2}} active/>
                                    </Col>
                                )))
                            )}
                        </Row>
                    </Radio.Group>
                )}

            </Col>


            {activeProduct && (
                <Row type="flex" align="middle" justify="center" className="text-center">
                    {(premium && (members < premiumAccounts) && user.fitlap_plus) ? (
                        <Alert className="default-alert" style={{marginBottom: 12}} message={<div><div style={{marginBottom: 6}}>{t('premium.family.limited')}</div></div>} type="error" showIcon/>
                    ) : (
                        <PaymentMethods active={paymentMethod} paymentMethods={activeProduct.methods} setPaymentMethod={setPaymentMethod} product={activeProduct}/>
                    )}
                </Row>
            )}
            <br/>
        </Row>
    );
};

export default withTranslation()(PaymentSection);
