import React, {useEffect, useRef, useState} from "react";
import {Button, Modal, Row, Typography} from "antd";
import ReactCanvasConfetti from 'react-canvas-confetti';
import LevelBadge from "../profile/level-badge.jsx";
import {isApp, ReactNativeMessage, trackEvent} from "../../helpers";
import {withTranslation} from "react-i18next";
import {ShareAltOutlined} from "@ant-design/icons";

const {Text} = Typography;

const LevelUpModal = ({data, t}) => {
    const [visible, setVisible] = useState(false);
    const confettiRef = useRef(null);

    useEffect(() => {
        if (data) {
            setVisible(true);
        }
    }, [data])

    const close = () => {
        setVisible(false);
    }

    useEffect(() => {
        setTimeout(() => {
            startConfetti();
        }, 500);

        setInterval(() => {
            startConfetti();
        }, 2000)
    }, [])

    const startConfetti = () => {
        if (confettiRef && confettiRef.current) {
            confettiRef.current.confetti();
        }
    }

    const share = () => {
        trackEvent('levelup share');

        if (isApp()) {
            ReactNativeMessage('FACEBOOK_SHARE https://bit.ly/fitlap-levelid');
        } else {
            window.FB.ui({
                method: 'share',
                href: 'https://bit.ly/fitlap-levelid',
                description: t('levelup.share.message')
            });
        }
    }

    return (
        <Modal
            className="main-modal level-up-modal"
            title={false}
            open={visible}
            onOk={close}
            onCancel={close}
            footer={false}
        >
            <ReactCanvasConfetti
                style={{
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: 500
                }}
                ref={confettiRef}
            />
            <h2>{t('level-up.title')}</h2>

            <LevelBadge level={data.level} type="animated"/>

            {data && (
                <div className="text-center">
                    <Text className="fade-in-content" style={{animationDelay: "1s", display: "block"}}>Level: <strong>{data.level}</strong>
                    </Text>

                    {data.reward.days && (
                        <Text className="fade-in-content" style={{animationDelay: "1.5s", display: "block"}}> {t('level.reward')}
                            <strong>{t('reward.days', {days: data.reward.days})}</strong></Text>
                    )}

                    {data.title && (
                        <Text className="fade-in-content" style={{animationDelay: "2s", display: "block"}}> {t('level.title')}
                            <strong>{data.title}</strong></Text>
                    )}
                </div>
            )}

            <Row className="text-center" type="flex" justify="center" style={{gap: "20px"}}>
                <Button size="large" icon={<ShareAltOutlined/>} onClick={share}>{t('cookbook.share.button')}</Button>
                <Button size="large" type="primary" onClick={close}>{t('common.continue')}</Button>
            </Row>
        </Modal>
    )
}

export default withTranslation()(LevelUpModal);